//
//
//
//
//
//
//
//
//

import { plural } from "~/util/helpers";
import ClockIcon from "~/components/icons/ClockIcon.vue";

export default {
    name: "ProductDiscountTimer",
    components: { ClockIcon },
    props: {
        product: {},
        endTime: {
            type: String,
            default: "2025-04-14T00:00:00",
        },
    },

    data() {
        return {
            remainingTime: "",
            endDateTime: null,
            countdown: 0,
            interval: null,
            correctProductsIds: [
                9241, 9759, 8546, 11312, 11699, 12039, 20638, 5649, 17321, 20736, 14563, 13518, 20666, 20740, 20737,
                20738, 9442, 20831, 20787, 15022,
            ],
        };
    },
    computed: {
        isCorrectProduct() {
            return this.correctProductsIds.includes(this.product.id);
        },
    },
    mounted() {
        this.endDateTime = new Date(this.endTime).getTime();
        this.updateCountdown();
        this.interval = setInterval(this.updateCountdown, 60000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        updateCountdown() {
            const now = new Date().getTime();

            const diff = this.endDateTime - now;
            this.countdown = diff;

            if (diff > 0) {
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

                const text =
                    days > 0
                        ? `${days} ${this.pluralizedDays(days)}`
                        : `${hours}:${minutes.toString().padStart(2, "0")}`;

                this.remainingTime = `Скидка еще ${text}`;
            } else {
                this.remainingTime = "";
            }
        },
        pluralizedDays(days) {
            return plural(days, "день", "дня", "дней");
        },
    },
    watch: {
        endTime(newValue) {
            if (newValue) {
                this.endDateTime = new Date(this.endTime).getTime();
                this.updateCountdown();
            }
        },
    },
};
